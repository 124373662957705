.hamburger {
  display: block;
  cursor: pointer;
  background-color: transparent;
  overflow: visible;
  position: relative;
  width: 28px;
  height: 19px;
}

.hamburger > div, .hamburger > view {
  display: block;
  top: 50%;
  margin-top: -1.5px;
}

.hamburger > div, .hamburger > div::before, .hamburger > div::after, .hamburger > view, .hamburger > view::before, .hamburger > view::after {
  width: 28px;
  height: 3px;
  background-color: #07539A;
  border-radius: 0;
  position: absolute;
  transition-property: all;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}

.hamburger > div::before, .hamburger > div::after, .hamburger > view::before, .hamburger > view::after {
  content: "";
  display: block;
}

.hamburger > div::before, .hamburger > view::before {
  top: -8px;
}

.hamburger > div::after, .hamburger > view::after {
  bottom: -8px;
}

.hamburger.hamburger-1 > div, .hamburger.hamburger-1 > view {
  top: auto;
  bottom: 0;
  transition-duration: 0.15s;
  transition-delay: 0.15s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger.hamburger-1 > div::after, .hamburger.hamburger-1 > view::after {
  top: -16px;
  transition: top 0.3s 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear, background-color 0.3s linear;
}

.hamburger.hamburger-1 > div::before, .hamburger.hamburger-1 > view::before {
  transition: top 0.12s 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0.3s linear;
}

.hamburger.hamburger-1.active > div, .hamburger.hamburger-1.active view {
  transform: translate3d(0, -8px, 0) rotate(-45deg);
  transition-delay: 0.32s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger.hamburger-1.active > div::after, .hamburger.hamburger-1.active view::after {
  top: 0;
  opacity: 0;
  transition: top 0.3s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.27s linear, background-color 0.3s linear;
}

.hamburger.hamburger-1.active > div::before, .hamburger.hamburger-1.active view::before {
  top: 0;
  transform: rotate(-90deg);
  transition: top 0.12s 0.18s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.15s 0.42s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0.3s linear;
}

.hamburger.hamburger-2 > div, .hamburger.hamburger-2 > view {
  top: auto;
  bottom: 0;
  transition-duration: 0.15s;
  transition-delay: 0.15s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger.hamburger-2 > div::after, .hamburger.hamburger-2 > view::after {
  top: -16px;
  transition: top 0.3s 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear, background-color 0.3s linear;
}

.hamburger.hamburger-2 > div::before, .hamburger.hamburger-2 > view::before {
  transition: top 0.12s 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0.3s linear;
}

.hamburger.hamburger-2.active > div, .hamburger.hamburger-2.active > view {
  transform: translate3d(0, -8px, 0) rotate(45deg);
  transition-delay: 0.32s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger.hamburger-2.active > div::after, .hamburger.hamburger-2.active > view::after {
  top: 0;
  opacity: 0;
  transition: top 0.3s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.27s linear, background-color 0.3s linear;
}

.hamburger.hamburger-2.active > div::before, .hamburger.hamburger-2.active > view::before {
  top: 0;
  transform: rotate(90deg);
  transition: top 0.12s 0.18s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.15s 0.42s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0.3s linear;
}

.hamburger.hamburger-3 > div, .hamburger.hamburger-3 > view {
  top: 0;
  transition-duration: .4s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  margin-top: 0;
}

.hamburger.hamburger-3 > div::before, .hamburger.hamburger-3 > view::before {
  top: 8px;
  transition: opacity 0.15s 0.4s ease, background-color 0.3s linear;
}

.hamburger.hamburger-3 > div::after, .hamburger.hamburger-3 > view::after {
  top: 16px;
  transition: transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55), background-color 0.3s linear;
}

.hamburger.hamburger-3.active > div, .hamburger.hamburger-3.active > view {
  transform: translate3d(0, 8px, 0) rotate(135deg);
  transition-delay: .1s;
}

.hamburger.hamburger-3.active > div::before, .hamburger.hamburger-3.active > view::before {
  transition-delay: 0s;
  opacity: 0;
}

.hamburger.hamburger-3.active > div:after, .hamburger.hamburger-3.active > view:after {
  transform: translate3d(0, -16px, 0) rotate(-270deg);
  transition-delay: .1s;
}

.hamburger.hamburger-4 > div, .hamburger.hamburger-4 > view {
  top: 0;
  transition-duration: .4s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  margin-top: 0;
}

.hamburger.hamburger-4 > div::before, .hamburger.hamburger-4 > view::before {
  top: 8px;
  transition: opacity 0.15s 0.4s ease, background-color 0.3s linear;
}

.hamburger.hamburger-4 > div::after, .hamburger.hamburger-4 > view::after {
  top: 16px;
  transition: transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55), background-color 0.3s linear;
}

.hamburger.hamburger-4.active > div, .hamburger.hamburger-4.active > view {
  transform: translate3d(0, 8px, 0) rotate(-135deg);
  transition-delay: .1s;
}

.hamburger.hamburger-4.active > div::before, .hamburger.hamburger-4.active > view::before {
  transition-delay: 0s;
  opacity: 0;
}

.hamburger.hamburger-4.active > div:after, .hamburger.hamburger-4.active > view:after {
  transform: translate3d(0, -16px, 0) rotate(270deg);
  transition-delay: .1s;
}

.hamburger.hamburger-5 > div, .hamburger.hamburger-5 > view {
  margin-top: -2px;
  transition: background-color 0.2s 0.25s ease-in, background-color 0.3s linear;
}

.hamburger.hamburger-5 > div::before, .hamburger.hamburger-5 > view::before {
  left: 0;
  transition: transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.2s linear, left 0.2s 0.25s ease-in, background-color 0.3s linear;
}

.hamburger.hamburger-5 > div::after, .hamburger.hamburger-5 > view::after {
  top: 8px;
  right: 0;
  transition: transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.2s linear, right 0.2s 0.25s ease-in, background-color 0.3s linear;
}

.hamburger.hamburger-5.active > div, .hamburger.hamburger-5.active > view {
  transition-delay: 0s;
  transition-timing-function: ease-out;
  background-color: transparent;
}

.hamburger.hamburger-5.active > div::before, .hamburger.hamburger-5.active > view::before {
  left: -56px;
  top: -56px;
  transform: translate3d(56px, 56px, 0) rotate(45deg);
  transition: left 0.2s ease-out, top 0.05s 0.2s linear, transform 0.2s 0.25s cubic-bezier(0.075, 0.82, 0.165, 1), background-color 0.3s linear;
}

.hamburger.hamburger-5.active > div::after, .hamburger.hamburger-5.active > view::after {
  right: -56px;
  top: -56px;
  transform: translate3d(-56px, 56px, 0) rotate(-45deg);
  transition: right 0.2s ease-out, top 0.05s 0.2s linear, transform 0.2s 0.25s cubic-bezier(0.075, 0.82, 0.165, 1), background-color 0.3s linear;
}

.hamburger.hamburger-6 > div, .hamburger.hamburger-6 > view {
  margin-top: -2px;
  transition: background-color 0.2s 0.25s ease-in, background-color 0.3s linear;
}

.hamburger.hamburger-6 > div::before, .hamburger.hamburger-6 > view::before {
  left: 0;
  transition: transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.2s linear, left 0.2s 0.25s ease-in, background-color 0.3s linear;
}

.hamburger.hamburger-6 > div::after, .hamburger.hamburger-6 > view::after {
  top: 8px;
  right: 0;
  transition: transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.2s linear, right 0.2s 0.25s ease-in, background-color 0.3s linear;
}

.hamburger.hamburger-6.active > div, .hamburger.hamburger-6.active > view {
  transition-delay: 0s;
  transition-timing-function: ease-out;
  background-color: transparent;
}

.hamburger.hamburger-6.active > div::before, .hamburger.hamburger-6.active > view::before {
  left: -56px;
  top: 56px;
  transform: translate3d(56px, -56px, 0) rotate(-45deg);
  transition: left 0.2s ease-out, top 0.05s 0.2s linear, transform 0.2s 0.25s cubic-bezier(0.075, 0.82, 0.165, 1), background-color 0.3s linear;
}

.hamburger.hamburger-6.active > div::after, .hamburger.hamburger-6.active > view::after {
  right: -56px;
  top: 56px;
  transform: translate3d(-56px, -56px, 0) rotate(45deg);
  transition: right 0.2s ease-out, top 0.05s 0.2s linear, transform 0.2s 0.25s cubic-bezier(0.075, 0.82, 0.165, 1), background-color 0.3s linear;
}

.hamburger.hamburger-7 > div, .hamburger.hamburger-7 > view {
  top: 0;
  margin-top: 0;
}

.hamburger.hamburger-7 > div::before, .hamburger.hamburger-7 > view::before {
  top: 8px;
  transition-property: transform, opacity, background-color;
  transition-timing-function: ease;
  transition-duration: .2s;
}

.hamburger.hamburger-7 > div::after, .hamburger.hamburger-7 > view::after {
  top: 16px;
}

.hamburger.hamburger-7.active > div, .hamburger.hamburger-7.active > view {
  transform: translate3d(0, 8px, 0) rotate(45deg);
}

.hamburger.hamburger-7.active > div::before, .hamburger.hamburger-7.active > view::before {
  transform: rotate(-45deg) translate3d(-4px, -5px, 0);
  opacity: 0;
}

.hamburger.hamburger-7.active > div::after, .hamburger.hamburger-7.active > view::after {
  transform: translate3d(0, -16px, 0) rotate(-90deg);
}

.hamburger.hamburger-8 > div, .hamburger.hamburger-8 > view {
  top: 0;
  margin-top: 0;
}

.hamburger.hamburger-8 > div::before, .hamburger.hamburger-8 > view::before {
  top: 8px;
  transition-property: transform, opacity, background-color;
  transition-timing-function: ease;
  transition-duration: .2s;
}

.hamburger.hamburger-8 > div::after, .hamburger.hamburger-8 > view::after {
  top: 16px;
}

.hamburger.hamburger-8.active > div, .hamburger.hamburger-8.active > view {
  transform: translate3d(0, 8px, 0) rotate(-45deg);
}

.hamburger.hamburger-8.active > div::before, .hamburger.hamburger-8.active > view::before {
  transform: rotate(45deg) translate3d(-4px, -5px, 0);
  opacity: 0;
}

.hamburger.hamburger-8.active > div::after, .hamburger.hamburger-8.active > view::after {
  transform: translate3d(0, -16px, 0) rotate(90deg);
}

.hamburger.hamburger-9 > div, .hamburger.hamburger-9 > view {
  margin-top: -2px;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger.hamburger-9 > div::before, .hamburger.hamburger-9 > view::before {
  transition: top 0.1s 0.34s ease-in, opacity 0.1s ease-in, background-color 0.3s linear;
}

.hamburger.hamburger-9 > div::after, .hamburger.hamburger-9 > view::after {
  transition: bottom 0.1s 0.34s ease-in, transform 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0.3s linear;
}

.hamburger.hamburger-9.active > div, .hamburger.hamburger-9.active > view {
  transform: rotate(225deg);
  transition-delay: 0.14s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger.hamburger-9.active > div::before, .hamburger.hamburger-9.active > view::before {
  top: 0;
  opacity: 0;
  transition: top .1s ease-out, opacity 0.1s 0.14s ease-out, background-color 0.3s linear;
}

.hamburger.hamburger-9.active > div::after, .hamburger.hamburger-9.active > view::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.1s ease-out, transform 0.3s 0.14s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0.3s linear;
}

.hamburger.hamburger-10 > div, .hamburger.hamburger-10 > view {
  margin-top: -2px;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger.hamburger-10 > div::before, .hamburger.hamburger-10 > view::before {
  transition: top 0.1s 0.34s ease-in, opacity 0.1s ease-in, background-color 0.3s linear;
}

.hamburger.hamburger-10 > div::after, .hamburger.hamburger-10 > view::after {
  transition: bottom 0.1s 0.34s ease-in, transform 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0.3s linear;
}

.hamburger.hamburger-10.active > div, .hamburger.hamburger-10.active > view {
  transform: rotate(-225deg);
  transition-delay: 0.14s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger.hamburger-10.active > div::before, .hamburger.hamburger-10.active > view::before {
  top: 0;
  opacity: 0;
  transition: top .1s ease-out, opacity 0.1s 0.14s ease-out, background-color 0.3s linear;
}

.hamburger.hamburger-10.active > div::after, .hamburger.hamburger-10.active > view::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.1s ease-out, transform 0.3s 0.14s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0.3s linear;
}

.hamburger.hamburger-11 > div, .hamburger.hamburger-11 > view {
  margin-top: -2px;
  transition-duration: .1s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger.hamburger-11 > div::before, .hamburger.hamburger-11 > view::before {
  transition: top 0.1s 0.14s ease, opacity 0.1s ease, background-color 0.3s linear;
}

.hamburger.hamburger-11 > div::after, .hamburger.hamburger-11 > view::after {
  transition: bottom 0.1s 0.14s ease, transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0.3s linear;
}

.hamburger.hamburger-11.active > div, .hamburger.hamburger-11.active > view {
  transform: rotate(45deg);
  transition-delay: 0.14s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger.hamburger-11.active > div::before, .hamburger.hamburger-11.active > view::before {
  top: 0;
  opacity: 0;
  transition: top .1s ease, opacity 0.1s 0.14s ease, background-color 0.3s linear;
}

.hamburger.hamburger-11.active > div::after, .hamburger.hamburger-11.active > view::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.1s ease, transform 0.1s 0.14s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0.3s linear;
}

.hamburger.hamburger-12 > div, .hamburger.hamburger-12 > view {
  margin-top: -2px;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.hamburger.hamburger-12 > div::before, .hamburger.hamburger-12 > div::after, .hamburger.hamburger-12 > view::before, .hamburger.hamburger-12 > view::after {
  transition-duration: 0s;
  transition-delay: .1s;
  transition-timing-function: linear;
}

.hamburger.hamburger-12 > div::before, .hamburger.hamburger-12 > view::before {
  transition-property: top, opacity, background-color;
}

.hamburger.hamburger-12 > div::after, .hamburger.hamburger-12 > view::after {
  transition-property: bottom, transform, background-color;
}

.hamburger.hamburger-12.active > div, .hamburger.hamburger-12.active > view {
  transform: rotate(765deg);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.hamburger.hamburger-12.active > div::before, .hamburger.hamburger-12.active > div::after, .hamburger.hamburger-12.active > view::before, .hamburger.hamburger-12.active > view::after {
  transition-delay: 0s;
}

.hamburger.hamburger-12.active > div::before, .hamburger.hamburger-12.active > view::before {
  top: 0;
  opacity: 0;
}

.hamburger.hamburger-12.active > div::after, .hamburger.hamburger-12.active > view::after {
  bottom: 0;
  transform: rotate(90deg);
}

.hamburger.hamburger-13 > div, .hamburger.hamburger-13 > view {
  margin-top: -2px;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.hamburger.hamburger-13 > div::before, .hamburger.hamburger-13 > div::after, .hamburger.hamburger-13 > view::before, .hamburger.hamburger-13 > view::after {
  transition-duration: 0s;
  transition-delay: .1s;
  transition-timing-function: linear;
}

.hamburger.hamburger-13 > div::before, .hamburger.hamburger-13 > view::before {
  transition-property: top, opacity;
}

.hamburger.hamburger-13 > div::after, .hamburger.hamburger-13 > view::after {
  transition-property: bottom, transform, background-color;
}

.hamburger.hamburger-13.active > div, .hamburger.hamburger-13.active > view {
  transform: rotate(-765deg);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.hamburger.hamburger-13.active > div::before, .hamburger.hamburger-13.active > div::after, .hamburger.hamburger-13.active > view::before, .hamburger.hamburger-13.active > view::after {
  transition-delay: 0s;
}

.hamburger.hamburger-13.active > div::before, .hamburger.hamburger-13.active > view::before {
  top: 0;
  opacity: 0;
}

.hamburger.hamburger-13.active > div::after, .hamburger.hamburger-13.active > view::after {
  bottom: 0;
  transform: rotate(-90deg);
}

@keyframes powerOn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes title-left {
  from {
    opacity: 0;
    transform: translateX(-100px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.index-banner {
  height: auto;
}

@media (max-width: 1600px) {
  .index-banner {
    height: 830px;
  }
}

@media (max-width: 1366px) {
  .index-banner {
    height: 700px;
  }
}

@media (max-width: 767px) {
  .index-banner {
    height: 450px;
  }
}

.index-banner .swiper {
  width: 100%;
  height: 100%;
}

.index-banner .swiper .swiper-slide {
  position: relative;
}

.index-banner .swiper .swiper-slide img {
  max-width: 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.index-banner .swiper .swiper-slide .title {
  position: absolute;
  top: 30%;
  padding-left: 163px;
  padding-right: 180px;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
}

@media (max-width: 1366px) {
  .index-banner .swiper .swiper-slide .title {
    top: 150px;
  }
}

@media (max-width: 767px) {
  .index-banner .swiper .swiper-slide .title {
    top: 100px;
  }
}

@media (max-width: 1600px) {
  .index-banner .swiper .swiper-slide .title {
    padding-left: 5%;
  }
}

@media (max-width: 767px) {
  .index-banner .swiper .swiper-slide .title {
    padding-left: 2%;
  }
}

@media (max-width: 1600px) {
  .index-banner .swiper .swiper-slide .title {
    padding-right: 5%;
  }
}

@media (max-width: 767px) {
  .index-banner .swiper .swiper-slide .title {
    padding-right: 2%;
  }
}

.index-banner .swiper .swiper-slide .title .text {
  color: #ffffff;
  width: 530px;
}

@media (max-width: 1600px) {
  .index-banner .swiper .swiper-slide .title .text {
    width: 30%;
  }
}

@media (max-width: 767px) {
  .index-banner .swiper .swiper-slide .title .text {
    width: 100%;
  }
}

.index-banner .swiper .swiper-slide .title .text h2 {
  opacity: 0;
  font-family: Roboto-Black;
  font-size: 46px;
  letter-spacing: 3px;
  line-height: 60px;
  margin-bottom: 60px;
}

@media (max-width: 1600px) {
  .index-banner .swiper .swiper-slide .title .text h2 {
    font-size: 42.8px;
  }
}

@media (max-width: 1366px) {
  .index-banner .swiper .swiper-slide .title .text h2 {
    font-size: 39.6px;
  }
}

@media (max-width: 1024px) {
  .index-banner .swiper .swiper-slide .title .text h2 {
    font-size: 36.4px;
  }
}

@media (max-width: 991px) {
  .index-banner .swiper .swiper-slide .title .text h2 {
    font-size: 33.2px;
  }
}

@media (max-width: 767px) {
  .index-banner .swiper .swiper-slide .title .text h2 {
    font-size: 30px;
  }
}

@media (max-width: 1600px) {
  .index-banner .swiper .swiper-slide .title .text h2 {
    letter-spacing: 2.8px;
  }
}

@media (max-width: 1366px) {
  .index-banner .swiper .swiper-slide .title .text h2 {
    letter-spacing: 2.6px;
  }
}

@media (max-width: 1024px) {
  .index-banner .swiper .swiper-slide .title .text h2 {
    letter-spacing: 2.4px;
  }
}

@media (max-width: 991px) {
  .index-banner .swiper .swiper-slide .title .text h2 {
    letter-spacing: 2.2px;
  }
}

@media (max-width: 767px) {
  .index-banner .swiper .swiper-slide .title .text h2 {
    letter-spacing: 2px;
  }
}

@media (max-width: 1600px) {
  .index-banner .swiper .swiper-slide .title .text h2 {
    line-height: 56px;
  }
}

@media (max-width: 1366px) {
  .index-banner .swiper .swiper-slide .title .text h2 {
    line-height: 52px;
  }
}

@media (max-width: 1024px) {
  .index-banner .swiper .swiper-slide .title .text h2 {
    line-height: 48px;
  }
}

@media (max-width: 991px) {
  .index-banner .swiper .swiper-slide .title .text h2 {
    line-height: 44px;
  }
}

@media (max-width: 767px) {
  .index-banner .swiper .swiper-slide .title .text h2 {
    line-height: 40px;
  }
}

@media (max-width: 1600px) {
  .index-banner .swiper .swiper-slide .title .text h2 {
    margin-bottom: 52px;
  }
}

@media (max-width: 1366px) {
  .index-banner .swiper .swiper-slide .title .text h2 {
    margin-bottom: 44px;
  }
}

@media (max-width: 1024px) {
  .index-banner .swiper .swiper-slide .title .text h2 {
    margin-bottom: 36px;
  }
}

@media (max-width: 991px) {
  .index-banner .swiper .swiper-slide .title .text h2 {
    margin-bottom: 28px;
  }
}

@media (max-width: 767px) {
  .index-banner .swiper .swiper-slide .title .text h2 {
    margin-bottom: 20px;
  }
}

.index-banner .swiper .swiper-slide .title .text p {
  opacity: 0;
  font-size: 24px;
}

@media (max-width: 1600px) {
  .index-banner .swiper .swiper-slide .title .text p {
    font-size: 23.2px;
  }
}

@media (max-width: 1366px) {
  .index-banner .swiper .swiper-slide .title .text p {
    font-size: 22.4px;
  }
}

@media (max-width: 1024px) {
  .index-banner .swiper .swiper-slide .title .text p {
    font-size: 21.6px;
  }
}

@media (max-width: 991px) {
  .index-banner .swiper .swiper-slide .title .text p {
    font-size: 20.8px;
  }
}

@media (max-width: 767px) {
  .index-banner .swiper .swiper-slide .title .text p {
    font-size: 20px;
  }
}

.index-banner .swiper .swiper-slide .title .text .btn {
  display: flex;
  align-items: center;
}

.index-banner .swiper .swiper-slide .title .text .more {
  opacity: 0;
  width: 215px;
  height: 62px;
  border-radius: 31px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  margin-top: 107px;
  transition: all .3s;
  position: relative;
  color: #fff;
  overflow: hidden;
  background-image: linear-gradient(-47deg, #2aacff 10%, #1980cd 37%, #07539a 65%);
  margin-right: 60px;
}

@media (max-width: 1600px) {
  .index-banner .swiper .swiper-slide .title .text .more {
    font-size: 19.2px;
  }
}

@media (max-width: 1366px) {
  .index-banner .swiper .swiper-slide .title .text .more {
    font-size: 18.4px;
  }
}

@media (max-width: 1024px) {
  .index-banner .swiper .swiper-slide .title .text .more {
    font-size: 17.6px;
  }
}

@media (max-width: 991px) {
  .index-banner .swiper .swiper-slide .title .text .more {
    font-size: 16.8px;
  }
}

@media (max-width: 767px) {
  .index-banner .swiper .swiper-slide .title .text .more {
    font-size: 16px;
  }
}

@media (max-width: 1600px) {
  .index-banner .swiper .swiper-slide .title .text .more {
    margin-top: 91.6px;
  }
}

@media (max-width: 1366px) {
  .index-banner .swiper .swiper-slide .title .text .more {
    margin-top: 76.2px;
  }
}

@media (max-width: 1024px) {
  .index-banner .swiper .swiper-slide .title .text .more {
    margin-top: 60.8px;
  }
}

@media (max-width: 991px) {
  .index-banner .swiper .swiper-slide .title .text .more {
    margin-top: 45.4px;
  }
}

@media (max-width: 767px) {
  .index-banner .swiper .swiper-slide .title .text .more {
    margin-top: 30px;
  }
}

@media (max-width: 1600px) {
  .index-banner .swiper .swiper-slide .title .text .more {
    margin-right: 52px;
  }
}

@media (max-width: 1366px) {
  .index-banner .swiper .swiper-slide .title .text .more {
    margin-right: 44px;
  }
}

@media (max-width: 1024px) {
  .index-banner .swiper .swiper-slide .title .text .more {
    margin-right: 36px;
  }
}

@media (max-width: 991px) {
  .index-banner .swiper .swiper-slide .title .text .more {
    margin-right: 28px;
  }
}

@media (max-width: 767px) {
  .index-banner .swiper .swiper-slide .title .text .more {
    margin-right: 20px;
  }
}

.index-banner .swiper .swiper-slide .title .text .more:last-child {
  margin-right: 0;
}

.index-banner .swiper .swiper-slide .title .pic {
  width: 793px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1600px) {
  .index-banner .swiper .swiper-slide .title .pic {
    width: 55%;
  }
}

@media (max-width: 767px) {
  .index-banner .swiper .swiper-slide .title .pic {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .index-banner .swiper .swiper-slide .title .pic {
    display: none;
  }
}

.index-banner .swiper .swiper-slide .title .pic img {
  width: 100%;
  max-width: 100%;
  height: auto;
  opacity: 0;
}

.index-banner .swiper .swiper-slide.swiper-slide-active .title .text h2 {
  animation-name: title-left;
  animation-duration: .2s;
  animation-delay: .1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.index-banner .swiper .swiper-slide.swiper-slide-active .title .text p {
  animation-name: title-left;
  animation-duration: .2s;
  animation-delay: .3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.index-banner .swiper .swiper-slide.swiper-slide-active .title .text a {
  animation-name: title-left;
  animation-duration: .2s;
  animation-delay: .5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.index-banner .swiper .swiper-slide.swiper-slide-active .title .pic img {
  animation-name: powerOn;
  animation-duration: .3s;
  animation-delay: .7s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.index-banner .swiper .swiper-pagination {
  bottom: 104px;
  width: fit-content;
  left: auto;
  right: 1.48rem;
}

@media (max-width: 1600px) {
  .index-banner .swiper .swiper-pagination {
    bottom: 80px;
  }
}

@media (max-width: 1366px) {
  .index-banner .swiper .swiper-pagination {
    bottom: 60px;
  }
}

@media (max-width: 1024px) {
  .index-banner .swiper .swiper-pagination {
    bottom: 40px;
  }
}

@media (max-width: 991px) {
  .index-banner .swiper .swiper-pagination {
    bottom: 20px;
  }
}

@media (max-width: 767px) {
  .index-banner .swiper .swiper-pagination {
    right: 20px;
  }
}

.index-banner .swiper .swiper-pagination .swiper-pagination-bullet {
  width: 20px;
  height: 20px;
  background: transparent;
  border: solid 1px #7d7d7d;
  opacity: 1;
  margin: 0;
  margin-right: 12px;
}

.index-banner .swiper .swiper-pagination .swiper-pagination-bullet:last-child {
  margin-right: 0;
}

.index-banner .swiper .swiper-pagination .swiper-pagination-bullet-active {
  background: url(./images/bullet.png) no-repeat;
  background-position: center;
}

.index .index-title {
  width: fit-content;
  margin: auto;
  font-family: Roboto-Bold;
  font-size: 48px;
  color: #303942;
}

@media (max-width: 1600px) {
  .index .index-title {
    font-size: 44.4px;
  }
}

@media (max-width: 1366px) {
  .index .index-title {
    font-size: 40.8px;
  }
}

@media (max-width: 1024px) {
  .index .index-title {
    font-size: 37.2px;
  }
}

@media (max-width: 991px) {
  .index .index-title {
    font-size: 33.6px;
  }
}

@media (max-width: 767px) {
  .index .index-title {
    font-size: 30px;
  }
}

.index .section1 {
  background: url(./images/index-bg1.jpg) no-repeat;
  background-size: cover;
  padding-top: 200px;
  padding-bottom: 190px;
}

@media (max-width: 1600px) {
  .index .section1 {
    padding-top: 168px;
  }
}

@media (max-width: 1366px) {
  .index .section1 {
    padding-top: 136px;
  }
}

@media (max-width: 1024px) {
  .index .section1 {
    padding-top: 104px;
  }
}

@media (max-width: 991px) {
  .index .section1 {
    padding-top: 72px;
  }
}

@media (max-width: 767px) {
  .index .section1 {
    padding-top: 40px;
  }
}

@media (max-width: 1600px) {
  .index .section1 {
    padding-bottom: 159.6px;
  }
}

@media (max-width: 1366px) {
  .index .section1 {
    padding-bottom: 129.2px;
  }
}

@media (max-width: 1024px) {
  .index .section1 {
    padding-bottom: 98.8px;
  }
}

@media (max-width: 991px) {
  .index .section1 {
    padding-bottom: 68.4px;
  }
}

@media (max-width: 767px) {
  .index .section1 {
    padding-bottom: 38px;
  }
}

.index .section1 ul {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 96px;
}

@media (max-width: 1600px) {
  .index .section1 ul {
    margin-top: 82.8px;
  }
}

@media (max-width: 1366px) {
  .index .section1 ul {
    margin-top: 69.6px;
  }
}

@media (max-width: 1024px) {
  .index .section1 ul {
    margin-top: 56.4px;
  }
}

@media (max-width: 991px) {
  .index .section1 ul {
    margin-top: 43.2px;
  }
}

@media (max-width: 767px) {
  .index .section1 ul {
    margin-top: 30px;
  }
}

.index .section1 ul li {
  width: 310px;
  transition: all .3s;
  margin-bottom: 0;
}

@media (max-width: 1600px) {
  .index .section1 ul li {
    width: 22%;
  }
}

@media (max-width: 767px) {
  .index .section1 ul li {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .index .section1 ul li {
    margin-bottom: 20px;
  }
}

.index .section1 ul li .pic {
  width: 100%;
  overflow: hidden;
  height: 360px;
  border-radius: 10px;
  transition: all .3s;
}

@media (max-width: 1600px) {
  .index .section1 ul li .pic {
    height: 300px;
  }
}

@media (max-width: 1366px) {
  .index .section1 ul li .pic {
    height: 240px;
  }
}

@media (max-width: 767px) {
  .index .section1 ul li .pic {
    height: auto;
  }
}

.index .section1 ul li .pic img {
  max-width: 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.index .section1 ul li .text {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 137px;
  font-size: 18px;
  color: #020202;
  transition: all .3s;
  text-align: center;
  padding-top: 0px;
  padding-bottom: 0px;
}

@media (max-width: 767px) {
  .index .section1 ul li .text {
    height: auto;
  }
}

@media (max-width: 1600px) {
  .index .section1 ul li .text {
    font-size: 17.6px;
  }
}

@media (max-width: 1366px) {
  .index .section1 ul li .text {
    font-size: 17.2px;
  }
}

@media (max-width: 1024px) {
  .index .section1 ul li .text {
    font-size: 16.8px;
  }
}

@media (max-width: 991px) {
  .index .section1 ul li .text {
    font-size: 16.4px;
  }
}

@media (max-width: 767px) {
  .index .section1 ul li .text {
    font-size: 16px;
  }
}

@media (max-width: 767px) {
  .index .section1 ul li .text {
    padding-top: 15px;
  }
}

@media (max-width: 767px) {
  .index .section1 ul li .text {
    padding-bottom: 15px;
  }
}

.index .section1 ul li span {
  display: block;
  width: 100%;
  height: 3px;
  background-color: #e5e5e5;
  transition: all .3s;
}

@media (max-width: 1600px) {
  .index .section1 ul li span {
    height: 2.8px;
  }
}

@media (max-width: 1366px) {
  .index .section1 ul li span {
    height: 2.6px;
  }
}

@media (max-width: 1024px) {
  .index .section1 ul li span {
    height: 2.4px;
  }
}

@media (max-width: 991px) {
  .index .section1 ul li span {
    height: 2.2px;
  }
}

@media (max-width: 767px) {
  .index .section1 ul li span {
    height: 2px;
  }
}

.index .section1 ul li:hover {
  width: 420px;
}

@media (max-width: 1600px) {
  .index .section1 ul li:hover {
    width: 30%;
  }
}

@media (max-width: 767px) {
  .index .section1 ul li:hover {
    width: 100%;
  }
}

.index .section1 ul li:hover .text {
  font-family: Roboto-Bold;
  font-size: 20px;
  color: #07539a;
}

@media (max-width: 1600px) {
  .index .section1 ul li:hover .text {
    font-size: 19.6px;
  }
}

@media (max-width: 1366px) {
  .index .section1 ul li:hover .text {
    font-size: 19.2px;
  }
}

@media (max-width: 1024px) {
  .index .section1 ul li:hover .text {
    font-size: 18.8px;
  }
}

@media (max-width: 991px) {
  .index .section1 ul li:hover .text {
    font-size: 18.4px;
  }
}

@media (max-width: 767px) {
  .index .section1 ul li:hover .text {
    font-size: 18px;
  }
}

.index .section1 ul li:hover span {
  background-color: #07539a;
}

.index .section2 {
  background: url(./images/index-bg2.jpg) no-repeat;
  background-size: cover;
  padding-top: 140px;
  padding-bottom: 190px;
}

@media (max-width: 1600px) {
  .index .section2 {
    padding-top: 117.6px;
  }
}

@media (max-width: 1366px) {
  .index .section2 {
    padding-top: 95.2px;
  }
}

@media (max-width: 1024px) {
  .index .section2 {
    padding-top: 72.8px;
  }
}

@media (max-width: 991px) {
  .index .section2 {
    padding-top: 50.4px;
  }
}

@media (max-width: 767px) {
  .index .section2 {
    padding-top: 28px;
  }
}

@media (max-width: 1600px) {
  .index .section2 {
    padding-bottom: 159.6px;
  }
}

@media (max-width: 1366px) {
  .index .section2 {
    padding-bottom: 129.2px;
  }
}

@media (max-width: 1024px) {
  .index .section2 {
    padding-bottom: 98.8px;
  }
}

@media (max-width: 991px) {
  .index .section2 {
    padding-bottom: 68.4px;
  }
}

@media (max-width: 767px) {
  .index .section2 {
    padding-bottom: 38px;
  }
}

.index .section2 ul {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 103px;
}

@media (max-width: 1600px) {
  .index .section2 ul {
    margin-top: 88.4px;
  }
}

@media (max-width: 1366px) {
  .index .section2 ul {
    margin-top: 73.8px;
  }
}

@media (max-width: 1024px) {
  .index .section2 ul {
    margin-top: 59.2px;
  }
}

@media (max-width: 991px) {
  .index .section2 ul {
    margin-top: 44.6px;
  }
}

@media (max-width: 767px) {
  .index .section2 ul {
    margin-top: 30px;
  }
}

.index .section2 ul li {
  width: calc(100% / 3);
  height: 310px;
  box-sizing: border-box;
  border: 1px solid #f2f4f8;
  background: #fff;
  transition: all .3s;
  padding-top: 60px;
}

@media (max-width: 767px) {
  .index .section2 ul li {
    width: 50%;
  }
}

@media (max-width: 1366px) {
  .index .section2 ul li {
    height: 300px;
  }
}

@media (max-width: 1024px) {
  .index .section2 ul li {
    height: 290px;
  }
}

@media (max-width: 767px) {
  .index .section2 ul li {
    height: 210px;
  }
}

@media (max-width: 1600px) {
  .index .section2 ul li {
    padding-top: 52px;
  }
}

@media (max-width: 1366px) {
  .index .section2 ul li {
    padding-top: 44px;
  }
}

@media (max-width: 1024px) {
  .index .section2 ul li {
    padding-top: 36px;
  }
}

@media (max-width: 991px) {
  .index .section2 ul li {
    padding-top: 28px;
  }
}

@media (max-width: 767px) {
  .index .section2 ul li {
    padding-top: 20px;
  }
}

.index .section2 ul li .pic {
  width: 136px;
  height: 136px;
  border: solid 1px #07539a;
  transition: all .3s;
  position: relative;
  border-radius: 50%;
  margin: auto;
}

@media (max-width: 1600px) {
  .index .section2 ul li .pic {
    width: 128.8px;
  }
}

@media (max-width: 1366px) {
  .index .section2 ul li .pic {
    width: 121.6px;
  }
}

@media (max-width: 1024px) {
  .index .section2 ul li .pic {
    width: 114.4px;
  }
}

@media (max-width: 991px) {
  .index .section2 ul li .pic {
    width: 107.2px;
  }
}

@media (max-width: 767px) {
  .index .section2 ul li .pic {
    width: 100px;
  }
}

@media (max-width: 1600px) {
  .index .section2 ul li .pic {
    height: 128.8px;
  }
}

@media (max-width: 1366px) {
  .index .section2 ul li .pic {
    height: 121.6px;
  }
}

@media (max-width: 1024px) {
  .index .section2 ul li .pic {
    height: 114.4px;
  }
}

@media (max-width: 991px) {
  .index .section2 ul li .pic {
    height: 107.2px;
  }
}

@media (max-width: 767px) {
  .index .section2 ul li .pic {
    height: 100px;
  }
}

.index .section2 ul li .pic img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all .3s;
}

.index .section2 ul li .pic img.img1 {
  opacity: 1;
}

.index .section2 ul li .pic img.img2 {
  opacity: 0;
}

.index .section2 ul li p {
  text-align: center;
  font-size: 20px;
  color: #020202;
  transition: all .3s;
  margin-top: 27px;
}

@media (max-width: 1600px) {
  .index .section2 ul li p {
    font-size: 19.2px;
  }
}

@media (max-width: 1366px) {
  .index .section2 ul li p {
    font-size: 18.4px;
  }
}

@media (max-width: 1024px) {
  .index .section2 ul li p {
    font-size: 17.6px;
  }
}

@media (max-width: 991px) {
  .index .section2 ul li p {
    font-size: 16.8px;
  }
}

@media (max-width: 767px) {
  .index .section2 ul li p {
    font-size: 16px;
  }
}

@media (max-width: 1600px) {
  .index .section2 ul li p {
    margin-top: 23.6px;
  }
}

@media (max-width: 1366px) {
  .index .section2 ul li p {
    margin-top: 20.2px;
  }
}

@media (max-width: 1024px) {
  .index .section2 ul li p {
    margin-top: 16.8px;
  }
}

@media (max-width: 991px) {
  .index .section2 ul li p {
    margin-top: 13.4px;
  }
}

@media (max-width: 767px) {
  .index .section2 ul li p {
    margin-top: 10px;
  }
}

.index .section2 ul li .more {
  width: 34px;
  height: 34px;
  background-color: #ffffff;
  border-radius: 50%;
  margin: auto;
  transition: all .3s;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

@media (max-width: 1600px) {
  .index .section2 ul li .more {
    margin-top: 18px;
  }
}

@media (max-width: 1366px) {
  .index .section2 ul li .more {
    margin-top: 16px;
  }
}

@media (max-width: 1024px) {
  .index .section2 ul li .more {
    margin-top: 14px;
  }
}

@media (max-width: 991px) {
  .index .section2 ul li .more {
    margin-top: 12px;
  }
}

@media (max-width: 767px) {
  .index .section2 ul li .more {
    margin-top: 10px;
  }
}

.index .section2 ul li .more span {
  color: #07539A;
  font-size: 20px;
}

.index .section2 ul li:hover {
  background-image: linear-gradient(-47deg, #2aacff 10%, #1980cd 37%, #07539a 65%);
  padding-top: 37px;
}

@media (max-width: 1600px) {
  .index .section2 ul li:hover {
    padding-top: 33.6px;
  }
}

@media (max-width: 1366px) {
  .index .section2 ul li:hover {
    padding-top: 30.2px;
  }
}

@media (max-width: 1024px) {
  .index .section2 ul li:hover {
    padding-top: 26.8px;
  }
}

@media (max-width: 991px) {
  .index .section2 ul li:hover {
    padding-top: 23.4px;
  }
}

@media (max-width: 767px) {
  .index .section2 ul li:hover {
    padding-top: 20px;
  }
}

.index .section2 ul li:hover .pic {
  border-color: #ffffff;
}

.index .section2 ul li:hover .pic img.img1 {
  opacity: 0;
}

.index .section2 ul li:hover .pic img.img2 {
  opacity: 1;
}

.index .section2 ul li:hover p {
  margin-top: 20px;
  color: #ffffff;
}

@media (max-width: 1600px) {
  .index .section2 ul li:hover p {
    margin-top: 18px;
  }
}

@media (max-width: 1366px) {
  .index .section2 ul li:hover p {
    margin-top: 16px;
  }
}

@media (max-width: 1024px) {
  .index .section2 ul li:hover p {
    margin-top: 14px;
  }
}

@media (max-width: 991px) {
  .index .section2 ul li:hover p {
    margin-top: 12px;
  }
}

@media (max-width: 767px) {
  .index .section2 ul li:hover p {
    margin-top: 10px;
  }
}

.index .section2 ul li:hover .more {
  opacity: 1;
}

.index .section3 {
  background: url(./images/index-bg3.jpg) no-repeat;
  background-size: cover;
  padding-top: 180px;
  padding-bottom: 96px;
}

@media (max-width: 1600px) {
  .index .section3 {
    padding-top: 151.2px;
  }
}

@media (max-width: 1366px) {
  .index .section3 {
    padding-top: 122.4px;
  }
}

@media (max-width: 1024px) {
  .index .section3 {
    padding-top: 93.6px;
  }
}

@media (max-width: 991px) {
  .index .section3 {
    padding-top: 64.8px;
  }
}

@media (max-width: 767px) {
  .index .section3 {
    padding-top: 36px;
  }
}

@media (max-width: 1600px) {
  .index .section3 {
    padding-bottom: 83.2px;
  }
}

@media (max-width: 1366px) {
  .index .section3 {
    padding-bottom: 70.4px;
  }
}

@media (max-width: 1024px) {
  .index .section3 {
    padding-bottom: 57.6px;
  }
}

@media (max-width: 991px) {
  .index .section3 {
    padding-bottom: 44.8px;
  }
}

@media (max-width: 767px) {
  .index .section3 {
    padding-bottom: 32px;
  }
}

.index .section3 ul {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 104px;
}

@media (max-width: 1600px) {
  .index .section3 ul {
    margin-top: 89.2px;
  }
}

@media (max-width: 1366px) {
  .index .section3 ul {
    margin-top: 74.4px;
  }
}

@media (max-width: 1024px) {
  .index .section3 ul {
    margin-top: 59.6px;
  }
}

@media (max-width: 991px) {
  .index .section3 ul {
    margin-top: 44.8px;
  }
}

@media (max-width: 767px) {
  .index .section3 ul {
    margin-top: 30px;
  }
}

.index .section3 ul li {
  width: 460px;
  margin-bottom: 0;
  transition: all .3s;
}

@media (max-width: 1600px) {
  .index .section3 ul li {
    width: 32%;
  }
}

@media (max-width: 767px) {
  .index .section3 ul li {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .index .section3 ul li {
    margin-bottom: 20px;
  }
}

.index .section3 ul li .pic {
  aspect-ratio: 460 / 300;
  overflow: hidden;
  position: relative;
  border-radius: 10px 10px 0px 0px;
}

.index .section3 ul li .pic img {
  max-width: 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all .3s;
}

.index .section3 ul li .pic span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 128px;
  height: 42px;
  background: rgba(7, 83, 154, 0.9);
  border-radius: 10px 0px 0px 0px;
  font-family: Calibri-Light;
  font-size: 22px;
  color: #ffffff;
  position: absolute;
  top: 0;
  left: 0;
}

@media (max-width: 1600px) {
  .index .section3 ul li .pic span {
    width: 122.4px;
  }
}

@media (max-width: 1366px) {
  .index .section3 ul li .pic span {
    width: 116.8px;
  }
}

@media (max-width: 1024px) {
  .index .section3 ul li .pic span {
    width: 111.2px;
  }
}

@media (max-width: 991px) {
  .index .section3 ul li .pic span {
    width: 105.6px;
  }
}

@media (max-width: 767px) {
  .index .section3 ul li .pic span {
    width: 100px;
  }
}

@media (max-width: 1600px) {
  .index .section3 ul li .pic span {
    height: 39.6px;
  }
}

@media (max-width: 1366px) {
  .index .section3 ul li .pic span {
    height: 37.2px;
  }
}

@media (max-width: 1024px) {
  .index .section3 ul li .pic span {
    height: 34.8px;
  }
}

@media (max-width: 991px) {
  .index .section3 ul li .pic span {
    height: 32.4px;
  }
}

@media (max-width: 767px) {
  .index .section3 ul li .pic span {
    height: 30px;
  }
}

@media (max-width: 1600px) {
  .index .section3 ul li .pic span {
    font-size: 21.2px;
  }
}

@media (max-width: 1366px) {
  .index .section3 ul li .pic span {
    font-size: 20.4px;
  }
}

@media (max-width: 1024px) {
  .index .section3 ul li .pic span {
    font-size: 19.6px;
  }
}

@media (max-width: 991px) {
  .index .section3 ul li .pic span {
    font-size: 18.8px;
  }
}

@media (max-width: 767px) {
  .index .section3 ul li .pic span {
    font-size: 18px;
  }
}

.index .section3 ul li .text {
  height: 240px;
  box-sizing: border-box;
  background-color: #ffffff;
  border-radius: 0px 0px 10px 10px;
  font-size: 18px;
  line-height: 34px;
  color: #020202;
  transition: all .3s;
  padding-left: 46px;
  padding-right: 46px;
  padding-top: 52px;
  padding-bottom: 42px;
}

@media (max-width: 767px) {
  .index .section3 ul li .text {
    height: auto;
  }
}

@media (max-width: 1600px) {
  .index .section3 ul li .text {
    font-size: 17.6px;
  }
}

@media (max-width: 1366px) {
  .index .section3 ul li .text {
    font-size: 17.2px;
  }
}

@media (max-width: 1024px) {
  .index .section3 ul li .text {
    font-size: 16.8px;
  }
}

@media (max-width: 991px) {
  .index .section3 ul li .text {
    font-size: 16.4px;
  }
}

@media (max-width: 767px) {
  .index .section3 ul li .text {
    font-size: 16px;
  }
}

@media (max-width: 1600px) {
  .index .section3 ul li .text {
    line-height: 32.2px;
  }
}

@media (max-width: 1366px) {
  .index .section3 ul li .text {
    line-height: 30.4px;
  }
}

@media (max-width: 1024px) {
  .index .section3 ul li .text {
    line-height: 28.6px;
  }
}

@media (max-width: 991px) {
  .index .section3 ul li .text {
    line-height: 26.8px;
  }
}

@media (max-width: 767px) {
  .index .section3 ul li .text {
    line-height: 25px;
  }
}

@media (max-width: 1600px) {
  .index .section3 ul li .text {
    padding-left: 39.8px;
  }
}

@media (max-width: 1366px) {
  .index .section3 ul li .text {
    padding-left: 33.6px;
  }
}

@media (max-width: 1024px) {
  .index .section3 ul li .text {
    padding-left: 27.4px;
  }
}

@media (max-width: 991px) {
  .index .section3 ul li .text {
    padding-left: 21.2px;
  }
}

@media (max-width: 767px) {
  .index .section3 ul li .text {
    padding-left: 15px;
  }
}

@media (max-width: 1600px) {
  .index .section3 ul li .text {
    padding-right: 39.8px;
  }
}

@media (max-width: 1366px) {
  .index .section3 ul li .text {
    padding-right: 33.6px;
  }
}

@media (max-width: 1024px) {
  .index .section3 ul li .text {
    padding-right: 27.4px;
  }
}

@media (max-width: 991px) {
  .index .section3 ul li .text {
    padding-right: 21.2px;
  }
}

@media (max-width: 767px) {
  .index .section3 ul li .text {
    padding-right: 15px;
  }
}

@media (max-width: 1600px) {
  .index .section3 ul li .text {
    padding-top: 44.6px;
  }
}

@media (max-width: 1366px) {
  .index .section3 ul li .text {
    padding-top: 37.2px;
  }
}

@media (max-width: 1024px) {
  .index .section3 ul li .text {
    padding-top: 29.8px;
  }
}

@media (max-width: 991px) {
  .index .section3 ul li .text {
    padding-top: 22.4px;
  }
}

@media (max-width: 767px) {
  .index .section3 ul li .text {
    padding-top: 15px;
  }
}

@media (max-width: 1600px) {
  .index .section3 ul li .text {
    padding-bottom: 36.6px;
  }
}

@media (max-width: 1366px) {
  .index .section3 ul li .text {
    padding-bottom: 31.2px;
  }
}

@media (max-width: 1024px) {
  .index .section3 ul li .text {
    padding-bottom: 25.8px;
  }
}

@media (max-width: 991px) {
  .index .section3 ul li .text {
    padding-bottom: 20.4px;
  }
}

@media (max-width: 767px) {
  .index .section3 ul li .text {
    padding-bottom: 15px;
  }
}

.index .section3 ul li .text .bottom {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}

@media (max-width: 1600px) {
  .index .section3 ul li .text .bottom {
    margin-top: 27px;
  }
}

@media (max-width: 1366px) {
  .index .section3 ul li .text .bottom {
    margin-top: 24px;
  }
}

@media (max-width: 1024px) {
  .index .section3 ul li .text .bottom {
    margin-top: 21px;
  }
}

@media (max-width: 991px) {
  .index .section3 ul li .text .bottom {
    margin-top: 18px;
  }
}

@media (max-width: 767px) {
  .index .section3 ul li .text .bottom {
    margin-top: 15px;
  }
}

.index .section3 ul li .text .bottom .more {
  width: 34px;
  height: 34px;
  background-color: #07539a;
  border-radius: 50%;
  transition: all .3s;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.index .section3 ul li .text .bottom .more span {
  color: #fff;
  font-size: 20px;
}

.index .section3 ul li:hover .pic img {
  transform: scale(1.1);
}

.index .section3 ul li:hover .text {
  color: #07539a;
}

.index .section3 ul li:hover .text .bottom .more {
  opacity: 1;
}

.index .section3 .news-more {
  width: 175px;
  height: 50px;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  margin-top: 48px;
  transition: all .3s;
  position: relative;
  color: #fff;
  overflow: hidden;
  background-image: linear-gradient(-46deg, #2aacff 10%, #1980cd 46%, #07539a 65%);
}

@media (max-width: 1600px) {
  .index .section3 .news-more {
    font-size: 19.2px;
  }
}

@media (max-width: 1366px) {
  .index .section3 .news-more {
    font-size: 18.4px;
  }
}

@media (max-width: 1024px) {
  .index .section3 .news-more {
    font-size: 17.6px;
  }
}

@media (max-width: 991px) {
  .index .section3 .news-more {
    font-size: 16.8px;
  }
}

@media (max-width: 767px) {
  .index .section3 .news-more {
    font-size: 16px;
  }
}

@media (max-width: 1600px) {
  .index .section3 .news-more {
    margin-top: 43.2px;
  }
}

@media (max-width: 1366px) {
  .index .section3 .news-more {
    margin-top: 38.4px;
  }
}

@media (max-width: 1024px) {
  .index .section3 .news-more {
    margin-top: 33.6px;
  }
}

@media (max-width: 991px) {
  .index .section3 .news-more {
    margin-top: 28.8px;
  }
}

@media (max-width: 767px) {
  .index .section3 .news-more {
    margin-top: 24px;
  }
}
