@import "../../assets/styles/utils";
@keyframes powerOn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes title-left {
    from {opacity: 0;transform: translateX(-100px);}
    to {opacity: 1;transform: translateX(0);}
}
.index-banner {
    @include res(height,auto,(lg:830px,mmd:700px,xs:450px));
    .swiper {
        width: 100%;
        height: 100%;
        .swiper-slide {
            position: relative;
            img {
                max-width: 100%;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            .title {
                position: absolute;
                @include res(top,30%,(mmd:150px,xs:100px));
                @include res(padding-left,163px,(lg:5%,xs:2%));
                @include res(padding-right,180px,(lg:5%,xs:2%));
                display: flex;
                align-items: stretch;
                justify-content: space-between;
                box-sizing: border-box;
                width: 100%;
                .text {
                    color: #ffffff;
                    @include res(width,530px,(lg:30%,xs:100%));
                    h2 {
                        opacity: 0;
                        font-family: Roboto-Black;
                        @include res(font-size,46px,(30 / 46));
                        @include res(letter-spacing,3px,(2 / 3));
                        @include res(line-height,60px,(2 / 3));
                        @include res(margin-bottom,60px,(1 / 3));
                    }
                    p {
                        opacity: 0;
                        @include res(font-size,24px,(20 / 24));
                    }
                    .btn {
                        display: flex;
                        align-items: center;
                    }
                    .more {
                        opacity: 0;
                        @include res(width,215px);
                        @include res(height,62px);
                        @include res(border-radius,31px);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        @include res(font-size,20px,(16 / 20));
                        @include res(margin-top,107px,(30 / 107));
                        transition: all .3s;
                        position: relative;
                        color: #fff;
                        overflow: hidden;
                        background-image:linear-gradient(-47deg,#2aacff 10%,#1980cd 37%,#07539a 65%);
                        @include res(margin-right,60px,(1 / 3));
                        &:last-child {
                            margin-right: 0;
                        }
                    }
                }
                .pic {
                    @include res(width,793px,(lg:55%,xs:100%));
                    @include res(display,flex,(xs:none));
                    align-items: center;
                    justify-content: center;
                    img {
                        width: 100%;
                        max-width: 100%;
                        height: auto;
                        opacity: 0;
                    }
                }
            }
            &.swiper-slide-active {
                .title {
                    .text {
                        h2 {
                            animation-name: title-left;
                            animation-duration: .2s;
                            animation-delay: .1s;
                            animation-iteration-count:1;
                            animation-fill-mode:forwards;
                        }
                        p {
                            animation-name: title-left;
                            animation-duration: .2s;
                            animation-delay: .3s;
                            animation-iteration-count:1;
                            animation-fill-mode:forwards;
                        }
                        a {
                            animation-name: title-left;
                            animation-duration: .2s;
                            animation-delay: .5s;
                            animation-iteration-count:1;
                            animation-fill-mode:forwards;
                        }
                    }
                    .pic {
                        img {
                            animation-name: powerOn;
                            animation-duration: .3s;
                            animation-delay: .7s;
                            animation-iteration-count:1;
                            animation-fill-mode:forwards;
                        }
                    }
                }
            }
        }
        .swiper-pagination {
            @include res(bottom,104px,(lg:80px,mmd:60px,md:40px,sm:20px));
            width: fit-content;
            left: auto;
            @include res(right,1.48rem,(xs:20px));
            .swiper-pagination-bullet {
                width: 20px;
	            height: 20px;
                background: transparent;
                border: solid 1px #7d7d7d;
                opacity: 1;
                margin: 0;
                margin-right: 12px;
                &:last-child {
                    margin-right: 0;
                }
            }
            .swiper-pagination-bullet-active {
                background: url(./images/bullet.png)no-repeat;
                background-position: center;
            }
        }
    }
}
.index {
    .index-title {
        width: fit-content;
        margin: auto;
        font-family: Roboto-Bold;
        @include res(font-size,48px,(30 / 48));
        color: #303942;
    }
    .section1 {
        background: url(./images/index-bg1.jpg)no-repeat;
        background-size: cover;
        @include res(padding-top,200px,(1 / 5));
        @include res(padding-bottom,190px,(1 / 5));
        ul {
            display: flex;
            align-items: stretch;
            justify-content: space-between;
            flex-wrap: wrap;
            @include res(margin-top,96px,(30 / 96));
            li {
                @include res(width,310px,(lg:22%,xs:100%));
                transition: all .3s;
                @include res(margin-bottom,0,(xs:20px));
                .pic {
                    width: 100%;
                    overflow: hidden;
                    @include res(height,360px,(lg:300px,mmd:240px,xs:auto));
                    border-radius: 10px;
                    transition: all .3s;
                    img {
                        max-width: 100%;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
                .text {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    @include res(height,137px,(xs:auto));
                    @include res(font-size,18px,(16 / 18));
                    color: #020202;
                    transition: all .3s;
                    text-align: center;
                    @include res(padding-top,0px,(xs:15px));
                    @include res(padding-bottom,0px,(xs:15px));
                }
                span {
                    display: block;
                    width: 100%;
                    @include res(height,3px,(2 / 3));
                    background-color: #e5e5e5;
                    transition: all .3s;
                }
                &:hover {
                    @include res(width,420px,(lg:30%,xs:100%));
                    .text {
                        font-family: Roboto-Bold;
                        @include res(font-size,20px,(18 / 20));
                        color: #07539a;
                    }
                    span {
                        background-color: #07539a;
                    }
                }
            }
        }
    }
    .section2 {
        background: url(./images/index-bg2.jpg)no-repeat;
        background-size: cover;
        @include res(padding-top,140px,(1 / 5));
        @include res(padding-bottom,190px,(1 / 5));
        ul {
            display: flex;
            align-items: stretch;
            justify-content: space-between;
            flex-wrap: wrap;
            @include res(margin-top,103px,(30 / 103));
            li {
                @include res(width,calc(100% / 3),(xs:50%));
                @include res(height,310px,(mmd:300px,md:290px,xs:210px));
                box-sizing: border-box;
                border: 1px solid #f2f4f8;
                background: #fff;
                transition: all .3s;
                @include res(padding-top,60px,(1 / 3));
                .pic {
                    @include res(width,136px,(100 / 136));
                    @include res(height,136px,(100 / 136));
                    border: solid 1px #07539a;
                    transition: all .3s;
                    position: relative;
                    border-radius: 50%;
                    margin: auto;
                    img {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%,-50%);
                        transition: all .3s;
                        &.img1 {
                            opacity: 1;
                        }
                        &.img2 {
                            opacity: 0;
                        }
                    }
                }
                p {
                    text-align: center;
                    @include res(font-size,20px,(16 / 20));
                    color: #020202;
                    transition: all .3s;
                    @include res(margin-top,27px,(10 / 27));
                }
                .more {
                    @include res(width,34px);
                    @include res(height,34px);
                    background-color: #ffffff;
                    border-radius: 50%;
                    margin: auto;
                    transition: all .3s;
                    opacity: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    @include res(margin-top,20px,(1 / 2));
                    span {
                        color: #07539A;
                        font-size: 20px;
                    }
                }
                &:hover {
                    background-image:linear-gradient(-47deg,#2aacff 10%,#1980cd 37%,#07539a 65%);
                    @include res(padding-top,37px,(20 / 37));
                    .pic {
                        border-color: #ffffff;
                        img {
                            &.img1 {
                                opacity: 0;
                            }
                            &.img2 {
                                opacity: 1;
                            }
                        }
                    }
                    p {
                        @include res(margin-top,20px,(1 / 2));
                        color: #ffffff;
                    }
                    .more {
                        opacity: 1;
                    }
                }
            }
        }
    }
    .section3 {
        background: url(./images/index-bg3.jpg)no-repeat;
        background-size: cover;
        @include res(padding-top,180px,(1 / 5));
        @include res(padding-bottom,96px,(1 / 3));
        ul {
            display: flex;
            align-items: stretch;
            justify-content: space-between;
            flex-wrap: wrap;
            @include res(margin-top,104px,(30 / 104));
            li {
                @include res(width,460px,(lg:32%,xs:100%));
                @include res(margin-bottom,0,(xs:20px));
                transition: all .3s;
                .pic {
                    aspect-ratio: 460 / 300;
                    overflow: hidden;
                    position: relative;
                    border-radius: 10px 10px 0px 0px;
                    img {
                        max-width: 100%;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        transition: all .3s;
                    }
                    span {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        @include res(width,128px,(100 / 128));
                        @include res(height,42px,(30 / 42));
                        background:rgba(7,83,154,.9);
                        border-radius: 10px 0px 0px 0px;
                        font-family: Calibri-Light;
                        @include res(font-size,22px,(18 / 22));
                        color: #ffffff;
                        position: absolute;
                        top: 0;
                        left: 0;
                    }
                }
                .text {
                    @include res(height,240px,(xs:auto));
                    box-sizing: border-box;
                    background-color: #ffffff;
                    border-radius: 0px 0px 10px 10px;
                    @include res(font-size,18px,(16 / 18));
                    @include res(line-height,34px,(25 / 34));
                    color: #020202;
                    transition: all .3s;
                    @include res(padding-left,46px,(15 / 46));
                    @include res(padding-right,46px,(15 / 46));
                    @include res(padding-top,52px,(15 / 52));
                    @include res(padding-bottom,42px,(15 / 42));
                    .bottom {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        @include res(margin-top,30px,(1 / 2));
                        .more {
                            @include res(width,34px);
                            @include res(height,34px);
                            background-color: #07539a;
                            border-radius: 50%;
                            transition: all .3s;
                            opacity: 0;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            span {
                                color: #fff;
                                font-size: 20px;
                            }
                        }
                    }
                }
                &:hover {
                    .pic {
                        img {
                            transform: scale(1.1);
                        }
                    }
                    .text {
                        color: #07539a;
                        .bottom {
                            .more {
                                opacity: 1;
                            }
                        }
                    }
                }
            }
        }
        .news-more {
            @include res(width,175px);
            @include res(height,50px);
            @include res(border-radius,25px);
            display: flex;
            align-items: center;
            justify-content: center;
            @include res(font-size,20px,(16 / 20));
            @include res(margin-top,48px,(1 / 2));
            transition: all .3s;
            position: relative;
            color: #fff;
            overflow: hidden;
            background-image:linear-gradient(-46deg,#2aacff 10%,#1980cd 46%,#07539a 65%);
        }
    }
}